header {
  background-color: #000059;
  vertical-align: middle;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;

  .logo {
    height: 30px;
    margin: 3px;
  }
}

[data-media-player][data-layout='video']:not([data-fullscreen]) {
  border-radius: 0px !important;
}

[data-media-player] {
  display: block;
}