.MatchDetails {
  @media screen and (min-width: 800px) {
    display: flex;

    .event-list {
      min-width: 300px;
    }
  }
  

  .center {
    flex-shrink: 1;
  }

  h1 {
    background-color: #000059;
    color:white;
    text-align: center;
    font-size: 15px;
    margin: 0px;
    padding: 4px;

  }

  .event-list {
    list-style-type: none;
    margin:0px;
    padding: 0px;
   
  }
}