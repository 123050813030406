
.event-item {
    border-bottom: 1px solid #000059;
    display: flex;
    padding: 3px;
    font-size: 15px;
    cursor: pointer;

    .box {
        height: 33px;
        width: 33px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../../public/play-button-svgrepo-com.svg");
        background-color: #000059;
        display: inline-block;
    }

    .time {
        line-height: 30px;
        flex-shrink: 0;
    }

    .title {
        padding-left: 5px;
        line-height: 30px;
        text-transform: capitalize;
        flex-grow: 2;
    }
}

